import styled from 'styled-components';

interface IScContainer {
	backgroundColor?: string;
}

export const ScBrownWrapper = styled.div<IScContainer>`
	background-color: ${({ theme, backgroundColor }) => backgroundColor ? backgroundColor : theme.colors.toasted};
	border-radius: 10px;
	padding: 24px 15px 50px 15px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 50px;
	img {
		width: 280px;
		height: auto;
		max-height: 600px;
	}
	@media (min-width: 1220px) {
		border-radius: 30px;
		padding: 45px 30px 50px 60px;
		display: flex;
		flex-direction: row;
		gap: 90px;
		align-items: center;
		img {
			width: 100%;
			height: auto;
		}
	}
	@media (min-width: 1440px) {
		border-radius: 30px;
		padding: 45px 30px 50px 60px;
		display: flex;
		flex-direction: row;
		gap: 90px;
		align-items: center;
		img {
			width: 100%;
			height: auto;
		}
	}
	@media (min-width: 1920px) {
		border-radius: 30px;
		padding: 50px 60px;
		display: flex;
		flex-direction: row;
		gap: 90px;
		img {
			width: 100%;
			height: auto;
		}
	}
`;

export const ScBrownTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	button {
		margin-top: 10px;
	}
	@media (min-width: 1220px) {
		align-items: start;
		gap: 60px;
		button {
			margin-top: 5px;
		}
	}
`;

export const ScImgWrapper = styled.div`

`;
